import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Unauthorized from '../views/Unauthorized.vue'
import WithoutArea from '../views/WithoutArea.vue'
import TempComponent from '../views/TempComponent.vue'
import PrivacyPolicies from '../views/PrivacyPolicies.vue';
import Login from '../views/admin/Login.vue'
import Users from '../views/admin/Users.vue'
import Roles from '../views/admin/Roles.vue'
import Permissions from '../views/admin/Permissions.vue'
import Areas from '../views/admin/Areas.vue'
import Sessions from '../views/admin/Sessions.vue'
import ListTicket from '../views/tickets/List.vue'
import NewTicket from '../views/tickets/New.vue'
import Statistics from '../views/tickets/Statistics.vue'
import AuthService from '@/services/auth';
import store from '../store';
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  {

    path: '/',
    redirect: '/tickets' // Redirige de '/' a '/tickets'

    //component: HomeView,
    /*redirect: to => {
      return 'tickets'
    },
    meta: {
      requiresAuth: true // 
    }*/
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: Users,
    meta: {
      requiresAuth: true // 
    }
  },
  {
    path: '/admin/permissions',
    name: 'admin.permissions',
    component: Permissions,
    meta: {
      requiresAuth: true // 
    }
  },
  {
    path: '/admin/roles',
    name: 'admin.roles',
    component: Roles,
    meta: {
      requiresAuth: true // 
    }
  },
  {
    path: '/admin/areas',
    name: 'admin.areas',
    component: Areas,
    meta: {
      requiresAuth: true // 
    }
  },
  {
    path: '/admin/sessions',
    name: 'admin.sessions',
    component: Sessions,
    meta: {
      requiresAuth: true // 
    }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: ListTicket,
    meta: {
      permission: 'tickets',
      requiresAuth: true // 
    },
    props: route => ({
      propOrderId: route.params.propOrderId,
      orderData: route.params.orderData
    })
  },
  {
    path: '/tickets/new',
    name: 'tickets.new',
    component: NewTicket,
    meta: {
      permission: 'tickets',
      requiresAuth: true // 
    }
  },
  {
    path: '/tickets/statistics',
    name: 'tickets.statistics',
    component: Statistics,
    meta: {
      permission: 'tickets',
      requiresAuth: true,
      requiresAdminOrJefe: true
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  {
    path: '/without-area',
    name: 'without-area',
    component: WithoutArea,
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/privacy-policies',
    name: 'privacy_policies',
    component: PrivacyPolicies,
  },
  { name: 'temp', path: '/temp/:dummyParam', component: TempComponent },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const isAuthenticated = await AuthService.checkAuthentication();
    if (!isAuthenticated) {
      next({ path: '/login' });
    } else {
      if (to.meta.permission) {
        const hasPermission = await checkUserPermission(to.meta.permission);
        if (hasPermission) {
          if(store.getters.getArea == undefined){
            return next({ path: '/without-area' });
          }         
          if (to.meta.requiresAdminOrJefe) {
            const user = store.getters.getUser;
            const area = store.getters.getArea;
            if (user.name && (user.area_user.level || user.roles[0].id == 1) && (area && (!area.non_resolving || user.id == 5))) {            
              return next();
            } else {
              return next({ path: '/unauthorized' });
            }
          }

          return next();
        } else {
          return next({ path: '/unauthorized' });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
async function checkUserPermission(permissionName) {
  try {
    const user = store.getters.getUser;
    if (user.name) {
      if (user.roles[0].permissions.find(per => per.name === permissionName)) {
        return true;
      } else {
        return false;
      }
    } else {
      // Si el usuario no está disponible, intentamos obtenerlo
      const token = localStorage.getItem("token");

      // Utilizamos await aquí para esperar la respuesta de la petición HTTP
      const response = await axios.post("/admin/getUser", { token: token });

      // Actualizamos el estado y permisos en Vuex
      store.commit('setUser', response.data.user);
      store.commit('setArea', response.data.user.areas[0]);
      //store.commit('updateP', response.data.user);

      // Devolvemos el resultado de la verificación de permisos
      return response.data.user.roles[0].permissions.find(per => per.name === permissionName) !== undefined;
    }
  } catch (error) {
    console.error('Error al verificar permisos:', error);
    return false;
  }
}
export default router
