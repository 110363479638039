import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: localStorage.getItem("token") !== null,
    //user : JSON.parse(localStorage.getIem("user")),
    user: {
      name: null,
    },
    permissions: {
      tickets: {
        path: '/tickets',
        status: false
      },
      home: {
        path: '/',
        status: false
      },
      admin: {
        path: '/admin',
        status: false
      },
    },
    area: {
      id: null,
      name: null
    },
    loadingApp : false,
    notification: null,
    notifications : []
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
    getUser: state => state.user,
    getArea: state => state.area,
    getLoadingApp: state => state.loadingApp,
  },
  mutations: {
    changeValueLoading(state, value){
      state.loadingApp = value;
    },
    updatePermission(state, obj) {
      //this.updatePermission({ permission: permissionName, status : true });
      if(state.permissions.hasOwnProperty(obj.permission)){
        state.permissions[obj.permission].status = obj.status;
      }
    },
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    clearPermissions(state) {
      state.permissions['tickets'].value = false;
      state.permissions['home'].value = false;
    },
    setArea(state, area) {
      state.area = area;
    },
    setNotification(state, notification) {
      //notification.status = 'activa';
      //state.notifications.push(notification);
      //console.log(notification);
      state.notification = notification;
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios.post("/login", {
          email: username,
          password: password,
          token_notification : localStorage.getItem("token_notification")
        }).then(res => {

          if (res.status == 200) {
            // Almacenar el token en localStorage
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
            localStorage.setItem("token", res.data.token);
            commit('setAuthentication', true);
            commit('setUser', res.data.user);
            commit('setArea', res.data.user.areas[0]);
            //Actualizamos los permisos
            commit('clearPermissions');
            res.data.user.roles[0].permissions.forEach((permission) => {

              const permissionName = permission.name;
              commit('updatePermission', { permission: permissionName, status: true });
            });
            
          }
          resolve(res);
        }).catch(error => {
          //console.error("Error during login:");
          //console.error(error.response.data.msg);
          reject(error);
        })
      })

    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post("/logout"
        ).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        })
      })
    },
  },
  modules: {
  }
})
