<style>
.table-row {
  background-color: #f6f6f6;
}
.cell-info {
  background-color: #e5f0ff;
}
.table-row-selected {
  background-color: #eafee1;
}
.pointer {
  cursor: pointer;
}
</style>
<template>
  <b-card>
    <div>
      <b-row>
        <b-col md="9" sm="12" cols="6">
          <h5>Tickets - Estadísticas</h5>
        </b-col>

        <template>
          <b-col align="right" md="3" sm="12">
            <b-button
              class="mr-3"
              @click="showFilters = !showFilters"
              title="Filtros"
              size="sm"
              variant="outline-primary"
            >
              <i class="fas fa-filter"></i>
            </b-button>
            <b-button @click="goToList" size="sm" variant="outline-primary">
              <b-icon icon="list-ul" variant="primary"></b-icon>
            </b-button>
          </b-col>
        </template>
      </b-row>

      <b-overlay :show="show" rounded="sm">
        <b-col class="pt-2 pb-2 pl-0 pr-0">
          <b-collapse v-model="showFilters" class="filter-collapse">
            <b-col cols="12">
              <b-row>
                <b-row>
                  <!-- Radio buttons para seleccionar el modo de entrada -->
                  <b-col cols="12" class="pr-4">
                    <b-form-group>
                      <b-form-text class="mt-0"> RANGO DE TIEMPO </b-form-text>
                      <b-form-radio-group
                        v-model="selectionMode"
                        name="selectionMode"
                        @change="clearInputs"
                        aria-required="true"
                        stacked
                      >
                        <b-form-radio value="days">Días</b-form-radio>
                        <b-form-radio value="dates">Fechas</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Selector de rango de días -->
                <b-col cols="2" v-if="isDaysRangeEnabled">
                  <b-form-text class="mt-0"> RANGO DE DÍAS </b-form-text>
                  <b-form-group>
                    <b-form-select
                      size="sm"
                      v-model="dateRangeSelected"
                      :options="dateRangeList"
                      required
                      @change="getTicketsFilterRange()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Selector de rango de fechas -->
                <b-col cols="5" v-if="isDateRangeEnabled">
                  <b-row>
                    <b-col cols="12" md="12" sm="12" xl="6" lg="6" class="pr-0">
                      <b-form-text class="mt-0"> DESDE </b-form-text>
                      <b-form-datepicker
                        placeholder="dd/mm/aaaa"
                        locale="es"
                        size="sm"
                        v-model="startDate"
                        @input="
                          endDate = '';
                          items = [];
                        "
                        v-on:change="items = []"
                        required
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                      ></b-form-datepicker>
                    </b-col>
                    <b-col cols="12" md="12" sm="12" xl="6" lg="6" class="pr-0">
                      <b-form-text class="mt-0"> HASTA </b-form-text>
                      <b-form-datepicker
                        placeholder="dd/mm/aaaa"
                        locale="es"
                        size="sm"
                        v-model="endDate"
                        required
                        :state="isEndDateValid"
                        @input="getTicketsFilterRange()"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback>
                        La fecha "Hasta" no puede ser menor que la fecha
                        "Desde".
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Check para mostrar los verificados -->
                <b-col
                  cols="2"
                  v-if="area.id == 3 || user.roles[0].id == 1 || user.id == 5"
                >
                  <b-form-text class="mt-0"> TIPO </b-form-text>
                  <b-form-group>
                    <b-form-select
                      size="sm"
                      v-model="typeSelected"
                      :options="typeList"
                      required
                      @change="getTicketsFilterRange()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-collapse>
        </b-col>
        <b-row class="pt-2">
          <div v-if="!area.non_resolving || user.id == 5">
            <b-col cols="12">
              <h6 class="pl-3 mt-1 subtitle">Recibidos</h6>
            </b-col>
            <b-col md="12" class="pb-2">
              <b-table
                class="table-rotation"
                responsive
                head-variant="light"
                fixed
                bordered
                small
                striped
                hoverstriped
                hover
                :items="items"
                :fields="fields"
              >
                <template #cell(actions)="row">
                  <b-icon
                    v-if="!isBoss"
                    @click="getAgentsByArea(row.item)"
                    icon="eye-fill"
                    class="border border-info rounded h6 m-0 pointer"
                    variant="info"
                  ></b-icon>
                </template>
              </b-table>
            </b-col>
          </div>
        </b-row>
        <b-row class="pt-2">
          <div v-if="selectedArea">
            <b-col cols="12">
              <h6 class="pl-3 mt-1 subtitle">
                Agentes de {{ selectedArea.area_destino ?? "" }}
              </h6>
            </b-col>
            <b-col md="12" class="pb-2" ref="agentsTable">
              <b-table
                class="table-rotation"
                responsive
                head-variant="light"
                fixed
                bordered
                small
                striped
                hoverstriped
                hover
                :items="itemsByAgents"
                :fields="fieldsAgents"
              >
                <template #cell(actions)="row">
                  <b-icon
                    @click="getTicketsFromAgent(row.item)"
                    icon="eye-fill"
                    class="border border-info rounded h6 m-0 pointer"
                    variant="info"
                  ></b-icon>
                </template>
                <template #cell(received)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'received',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.received }}
                  </div>
                </template>
                <template #cell(pending)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'pending',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.pending }}
                  </div>
                </template>
                <template #cell(finished)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'finished',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.finished }}
                  </div>
                </template>
                <template #cell(derived)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'derived',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ getDerived(row.item.area_id, false) }}
                  </div>
                </template>
                <template #cell(average_reading)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'reading',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_reading }}
                  </div>
                </template>
                <template #cell(average_intake)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'take',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_intake }}
                  </div>
                </template>
                <template #cell(average_resolution)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'resolution',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_resolution }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </div>
        </b-row>
      </b-overlay>
    </div>
    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        v-if="!isDelete"
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
      <b-row v-if="isDelete">
        <b-col cols="6" align="center">
          <b-button
            class="mt-3"
            variant="secondary"
            @click="$bvModal.hide('msj-modal')"
            >Cancelar</b-button
          >
        </b-col>
        <b-col cols="6" align="center">
          <b-button class="mt-3" variant="danger" @click="deleteOperation"
            >Confirmar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- Tickets modal -->
    <b-modal
      id="detail-tickets-modal"
      size="xl"
      centered
      hide-footer
      :title="`Tickets - Agente ${agentSelected ? agentSelected.name : ''}`"
    >
      <template>
        <button
          type="button"
          class="close"
          @click="$bvModal.hide('detail-tickets-modal')"
        ></button>
      </template>
      <b-overlay
        :show="showLoadingModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div>
          <b-col md="12" class="pb-2">
            <b-input-group class="mb-3">
              <b-form-input
                v-model="filter"
                placeholder="Buscar..."
                class="mr-2"
              ></b-form-input>
            </b-input-group>
            <b-table
              class="table-rotation"
              responsive
              head-variant="light"
              fixed
              bordered
              small
              striped
              hoverstriped
              hover
              :filter="filter"
              :items="itemsModal"
              :fields="fieldsModal"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template #cell(received)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'received',
                      'received',
                      'status',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.received }}
                </div>
              </template>
              <template #cell(pending)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'pending',
                      'received',
                      'status',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.pending }}
                </div>
              </template>
              <template #cell(finished)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'finished',
                      'received',
                      'status',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.finished }}
                </div>
              </template>
              <template #cell(derived)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'derived',
                      'received',
                      'status',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ getDerived(row.item.area_id, false) }}
                </div>
              </template>
              <template #cell(average_reading)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'reading',
                      'received',
                      'time',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.average_reading }}
                </div>
              </template>
              <template #cell(average_intake)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'take',
                      'received',
                      'time',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.average_intake }}
                </div>
              </template>
              <template #cell(average_resolution)="row">
                <div
                  class="pointer"
                  @click="
                    getTicketsSpecific(
                      row.item.area_id,
                      'resolution',
                      'received',
                      'time',
                      row.item.area_name,
                      'recibidos'
                    )
                  "
                >
                  {{ row.item.average_resolution }}
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              class="mt-3"
            ></b-pagination>
          </b-col>
        </div>
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  props: ["rol"],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filter: "",
      itemsByAgents: [],
      isBoss: false,
      typeList: [
        { text: "Sin Consulta Bancaria", value: "no_consulta_bancaria" },
        { text: "Consulta Bancaria", value: "consulta_bancaria" },
        { text: "Todos", value: "todos" },
      ],
      typeSelected: "no_consulta_bancaria",
      selectionMode: "days", // Modo de entrada seleccionado ('days' o 'dates')
      isVerifiedTickets: false,
      dateRangeSelected: null, // Modelo para el selector de rango de días
      startDate: "", // Fecha de inicio
      endDate: "", // Fecha de fin
      isDaysRangeEnabled: true, // Controla el estado del selector de rango de días
      isDateRangeEnabled: false, // Controla el estado de los campos de fecha
      showFilters: true,
      selectedPeriod: "all",
      options: [
        { text: "Hoy", value: "today" },
        { text: "30 días", value: "30days" },
        { text: "Todos los registros", value: "all" },
      ],
      parametersTitleModal: {
        areaName: null,
        type: null,
      },
      cellhover: false,
      userCanAdd: null,
      dateRangeSelected: 0,
      dateRangeRealizedSelected: 1,
      dateRangeList: [
        { text: "Seleccione una opción...", value: null },
        { text: "Hoy", value: 0 },
        { text: "30 días", value: 1 },
        { text: "60 días", value: 2 },
        { text: "90 días", value: 3 },
        { text: "Todos", value: 4 },
      ],
      msjModal: "",
      items: [],
      itemsModal: [],
      itemsRealized: [],
      itemsModalTickets: [],
      derivados: [],
      derivadosRealized: [],
      show: false,
      userId: null,
      rolId: null,
      areaId: null,
      fieldsAgents: [],
      fieldsAgentsWithOutVerified: [
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_en_proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_finalizados",
          label: "FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_derivados",
          label: "DERIVADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_completion_time",
          label: "TIEMPO MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsAgentsWithVerified: [
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_en_proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_finalizados",
          label: "FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_verificados",
          label: "VERIFICADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_derivados",
          label: "DERIVADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_completion_time",
          label: "TIEMPO MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_verification_time",
          label: "TIEMPO MEDIO VERIFICACIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fields: [],
      fieldsWithOutVerified: [
        {
          key: "area_destino",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[1]",
          label: "T.MEDIO LECTURA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[2]",
          label: "T.MEDIO TOMA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[3]",
          label: "T.MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsWithVerified: [
        {
          key: "area_destino",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado ok",
          label: "VERIFICADO",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "times[1]",
          label: "T.MEDIO LECTURA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[2]",
          label: "T.MEDIO TOMA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[3]",
          label: "T.MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsRealized: [
        {
          key: "area_origen",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado ok",
          label: "VERIFICADA",
          thStyle: { width: "95px" },
          sortable: true,
        },
      ],
      fieldsModal: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "30px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "120px" },
          sortable: true,
        },
        {
          key: "status",
          label: "ESTADO",
          thStyle: { width: "80px" },
          sortable: true,
        },
      ],
      fieldsModalTime: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "time",
          label: "TIEMPO",
          thStyle: { width: "95px" },
          sortable: true,
        },
      ],
      fieldsModalStatus: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "95px" },
          sortable: true,
        },
        /*{
          key: "time",
          label: "TIEMPO",
          thStyle: { width: "95px" },
          sortable: true,
        },*/
      ],
      showLoadingModal: false,
      isDelete: false,
      rowModalSelected: null,
      selectedArea: null,
      agentSelected: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
    isEndDateValid() {
      return this.endDate >= this.startDate || this.endDate === "";
    },
    isFormValid() {
      return this.startDate !== "" && this.isEndDateValid;
    },
    totalRows() {
      return this.itemsModal.length;
    },
  },
  mounted() {
    if (this.area.id == 3) {
      this.typeSelected = "todos";
    }
    //Verificamos si el usuario cuenta con un area asignada
    if (this.area != undefined) {
      this.getUser();
      this.getArea();
      this.getTicketsFilterRange();
    } else {
      this.userWithoutArea = true;
    }
    this.fieldsAgents = this.fieldsAgentsWithVerified;
  },
  methods: {
    getTicketsFromAgent(agent) {
      this.show = true;
      this.agentSelected = agent;
      this.$axios
        .get("/order/getTicketsFromAgent", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            rol_id: this.rolId,
            agent_id: agent.id,
          },
        })
        .then((response) => {
          this.itemsModal = response.data;
          this.$bvModal.show("detail-tickets-modal");
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getAgentsByArea(item) {
      this.show = true;
      this.selectedArea = item;
      this.$axios
        .get("/order/getAgentsByArea", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            area_id: item.areaId,
            rol_id: this.rolId,
            user_id: this.userId,
          },
        })
        .then((response) => {
          this.itemsByAgents = response.data.agentsStatistics;
          //this.$bvModal.show("detail-tickets-modal");
          this.show = false;
          const elemento = this.$refs.agentsTable;
          if (elemento) {
            elemento.scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    clearInputs() {
      if (this.selectionMode === "days") {
        this.isDateRangeEnabled = false;
        this.isDaysRangeEnabled = true;
        this.dateRangeSelected = null;
      } else if (this.selectionMode === "dates") {
        this.isDateRangeEnabled = true;
        this.isDaysRangeEnabled = false;
      }
      this.items = [];
    },
    getTicketsSpecific(areaId, type, tableName, sorting, areaName, typeName) {
      this.parametersTitleModal.areaName = areaName;
      this.parametersTitleModal.type = typeName;
      this.show = true;
      this.$axios
        .get("/order/getTicketsSpecific", {
          params: {
            area_id: areaId,
            type: type,
            table_name: tableName,
            sorting: sorting,
          },
        })
        .then((response) => {
          this.itemsModalTickets = [...response.data];
          if (sorting == "time") {
            this.fieldsModal = this.fieldsModalTime;
          } else {
            this.fieldsModal = this.fieldsModalStatus;
          }
          this.$bvModal.show("detail-tickets-modal");
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getTicketsFilterRange() {
      this.selectedArea = null;
      this.itemsByAgents = null;
      this.show = true;
      this.$axios
        .get("/order/getStatistics", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            area_id: this.areaId,
            rol_id: this.rolId,
            user_id: this.userId,
          },
        })
        .then((response) => {
          //Tiempos
          const averagesByAreaTicketsReceived =
            response.data.averagesByAreaTicketsReceived;
          const ticketsReceived = response.data.ticketsReceived.reduce(
            (acc, item) => {
              let area = acc.find((a) => a.area_destino === item.area_destino);
              if (!area) {
                area = {
                  areaId: item.area_id,
                  area_destino: item.area_destino,
                  pendiente: 0,
                  leído: 0,
                  "en proceso": 0,
                  finalizado: 0,
                  rechazado: 0,
                  derivado: 0,
                  "finalizado ok": 0,
                };
                acc.push(area);
              }
              area[item.status_name] = parseInt(item.total_tickets);
              return acc;
            },
            []
          );
          this.items = ticketsReceived;
          this.items.map((item) => {
            if (averagesByAreaTicketsReceived.hasOwnProperty(item.areaId)) {
              item.times = averagesByAreaTicketsReceived[item.areaId];
            } else {
              item.times = [];
            }
            return item;
          });
          //Verificamos el typo de ticket seleccionado
          if (this.typeSelected == "no_consulta_bancaria") {
            this.fields = this.fieldsWithOutVerified;
            this.fieldsAgents = this.fieldsAgentsWithOutVerified;
          } else if (this.typeSelected == "consulta_bancaria") {
            this.fields = this.fieldsWithVerified;
            this.fieldsAgents = this.fieldsAgentsWithVerified;
          } else if (this.typeSelected == "todos") {
            this.fields = this.fieldsWithVerified;
            this.fieldsAgents = this.fieldsAgentsWithVerified;
          }
          //Verificamos si es un JEFE, para mostrar la tabla de agentes
          if (this.rolId != 1 && this.userId != 5) {
            this.isBoss = true;
            this.itemsByAgents = response.data.agentsStatistics;
            this.selectedArea = this.items[0];
          }
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getDerived(areaId, isRealized) {
      let area = null;
      if (isRealized) {
        area = this.derivadosRealized.find((e) => e.id == areaId);
      } else {
        area = this.derivados.find((e) => e.id == areaId);
      }
      if (area) {
        return area.derived;
      }
      return 0;
    },
    goToList() {
      window.location.href = "/tickets";
    },
    getUser() {
      //console.log(this.user.roles[0].id)
      this.userId = this.user.id;
      this.rolId = this.user.roles[0].id;
      // quito permisos a usuario que no es rol 1 2 3
    },
    getArea() {
      this.areaId = this.area.id;
    },
  },
};
</script>
<style scoped>
.subtitle {
  background-color: #0076ad;
  color: white;
  padding: 3pt;
  margin-bottom: 3pt;
}
.filter-collapse {
  background-color: #f1f7fb;
  padding: 3pt 3pt 1pt 3pt;
}
</style>